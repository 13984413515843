<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>투어상담 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="username">고객명</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">타입</p>
        <label>
          <input type="radio" value="" checked v-model="tourType" />전체
        </label>
        <label>
          <input type="radio" value="collection" v-model="tourType" />
          웨딩 컬렉션
        </label>
        <label>
          <input type="radio" value="weddingPlace" v-model="tourType" />
          웨딩장소
        </label>
        <label>
          <input type="radio" value="planner" v-model="tourType" />
          플래너
        </label>
      </div>
      <!-- <div class="flex">
        <p class="bold">카테고리</p>
        <div class="labelBox">
          <label>
            <input type="radio" value="" checked v-model="category" />전체
          </label>
          <label>
            <input type="radio" value="STUDIO" v-model="category" />
            사진
          </label>
          <label>
            <input type="radio" value="DRESS" v-model="category" />
            드레스/예복
          </label>
          <label>
            <input type="radio" value="MAKEUP" v-model="category" />
            헤어&#38;메이크업/부케
          </label>
          <label>
            <input type="radio" value="HONEYMOON" v-model="category" />
            허니문
          </label>
          <label>
            <input type="radio" value="VIDEO" v-model="category" />
            웨딩 영상
          </label>
          <label>
            <input type="radio" value="EVENT" v-model="category" />
            주례&#38;사회/이벤트
          </label>
          <label>
            <input type="radio" value="GIFT" v-model="category" />
            예물
          </label>
          <label>
            <input type="radio" value="HANBOK" v-model="category" />
            한복/예단
          </label>
          <label>
            <input type="radio" value="PYEBAEK" v-model="category" />
            폐백&#38;이바지/청첩장
          </label>
          <label>
            <input type="radio" value="BEAUTY" v-model="category" />
            뷰티케어
          </label>
          <label>
            <input type="radio" value="INTERIOR" v-model="category" />
            가전/가구/인테리어
          </label>
          <label>
            <input type="radio" value="CATERING" v-model="category" />
            케이터링
          </label>
        </div>
      </div> -->
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one collection">
      <h2 class="bold">
        투어상담 리스트
        <!-- <button class="right basic">
          <router-link to="/admin/tour/gift">웨딩 투어 등록</router-link>
        </button> -->
      </h2>
      <table>
        <colgroup>
          <col style="width: 5%" />
          <col style="width: 8%" />
          <col style="width: 10%" />
          <col style="width: 12%" />
          <col style="width: 8%" />
          <col style="width: 10%" />
          <col style="width: 6%" />
          <col style="width: 6%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>고객명</th>
          <th>타입</th>
          <th>카테고리</th>
          <th>담당 플래너</th>
          <th>상담요청일</th>
          <th>상담</th>
          <th>계약</th>
        </tr>
        <tr v-for="(data, i) in tourList" :key="i">
          <td>
            <router-link to @click.native="handleMove(data._id)">
              {{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</router-link
            >
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{
              data.user?.username
            }}</router-link>
          </td>
          <td>
            {{
              data.tourType == "collection"
                ? "웨딩 컬렉션"
                : data.tourType == "planner"
                ? "플래너"
                : data.tourType == "weddingPlace"
                ? "웨딩장소"
                : ""
            }}
          </td>
          <td v-if="data.tourType == 'collection'">
            {{
              data.collectionId?.category == "INTERIOR"
                ? "가전/가구/인테리어"
                : data.collectionId?.category == "STUDIO"
                ? "사진"
                : data.collectionId?.category == "DRESS"
                ? "드레스/예복"
                : data.collectionId?.category == "MAKEUP"
                ? "헤어&#38;메이크업/부케"
                : data.collectionId?.category == "HONEYMOON"
                ? "허니문"
                : data.collectionId?.category == "VIDEO"
                ? "웨딩 영상"
                : data.collectionId?.category == "EVENT"
                ? "주례&#38;사회/이벤트"
                : data.collectionId?.category == "GIFT"
                ? "예물"
                : data.collectionId?.category == "HANBOK"
                ? "한복/예단"
                : data.collectionId?.category == "PYEBAEK"
                ? "폐백&#38;이바지/청첩장"
                : data.collectionId?.category == "BEAUTY"
                ? "뷰티케어"
                : data.collectionId?.category == "CATERING"
                ? "케이터링"
                : ""
            }}
          </td>
          <td v-else-if="data.tourType == 'planner'">
            {{
              data.planners?.plannerType == "PLANNER"
                ? "웨딩플래너"
                : data.planners?.plannerType == "DIRECTOR"
                ? "디렉터"
                : "플래너"
            }}
          </td>
          <td v-else-if="data.tourType == 'weddingPlace'">
            {{
              data.weddingPlace?.category == "HALL"
                ? "홀"
                : data.weddingPlace?.category == "HOUSE"
                ? "스몰/야외&#38;하우스웨딩"
                : data.weddingPlace?.category == "HOTEL"
                ? "호텔"
                : data.weddingPlace?.category == "MEETING"
                ? "상견례"
                : data.weddingPlace?.category == "BIRTHDAY"
                ? "돌잔치"
                : data.weddingPlace?.category == "TRADITION"
                ? "전통 혼례"
                : ""
            }}
          </td>
          <td>{{ data.user?.planner?.plannerName }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
          <td>{{ data.consultStamp ? "완료" : "-" }}</td>
          <td>{{ data.contractStamp ? "완료" : "-" }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="tourList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchTourList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      tourList: [],
      inputMode: "",
      searchWord: "",
      tourType: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "13-1");
  },
  mounted() {
    this.getTourList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.currentPage = 1;
      this.tourList = [];
      this.total = 0;
      this.getTourList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getTourList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTourList();
    },
    handleMove(id) {
      this.$router.push({ name: "tourRegister", query: { id: id } });
    },
    getTourList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        tourType: this.tourType,
      };
      fetchTourList(params).then((res) => {
        if (res.data.status == 200) {
          this.tourList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
